var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":16}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Information de l'agence")])]},proxy:true}])},[_c('div',{staticClass:"text-right mb-4"},[_c('router-link',{attrs:{"to":{ name: 'Agence_depot', params: { id: _vm.agence.id } }}},[_c('a-button',{staticClass:"mx-2",attrs:{"type":"primary"}},[_vm._v("Liste des versements")])],1),_c('router-link',{attrs:{"to":{ name: 'Agence_carnet_detail', params: { id: _vm.agence.id } }}},[_c('a-button',{staticClass:"mx-2",attrs:{"type":"primary"}},[_vm._v("Affectation carnets")])],1),_c('router-link',{attrs:{"to":{ name: 'Agence_client_detail', params: { id: _vm.agence.id } }}},[_c('a-button',{staticClass:"mx-2",attrs:{"type":"primary"}},[_vm._v("Client inactif")])],1),_c('a-button',{staticClass:"mx-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")])],1),_c('a-row',{attrs:{"gutter":[24, 24]}},[_c('a-col',{attrs:{"span":24}},[_c('a-card',{staticClass:"card-billing-info",attrs:{"bordered":false}},[_c('div',{staticClass:"col-info"},[_c('a-descriptions',{attrs:{"title":'Date de création: ' +
                      new Date(_vm.agence.created_at).toLocaleString(),"column":3}},[_c('a-descriptions-item',{attrs:{"label":"Nom"}},[_vm._v(" "+_vm._s(_vm.agence.nom_agence)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Ville"}},[_vm._v(" "+_vm._s(_vm.ville)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Quartier"}},[_vm._v(" "+_vm._s(_vm.quartier)+" ")])],1)],1)])],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Statistique de l'agence")])]},proxy:true}])},[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":12,"lg":12,"xl":12}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1)],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v(" Liste des collecteurs affectés ")])]},proxy:true}])},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('router-link',{staticClass:"mx-2",attrs:{"to":{
                      name: 'Collecteur_detail',
                      params: { id: record.key },
                    }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)]}}])})],1)],1)],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":8,"md":8}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: '20px', paddingBottom: '16px' }}},[[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Affecter un agent collecteur")])],_c('a-form',{staticClass:"affect-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.formAffect,"hideRequiredMark":true},on:{"submit":_vm.affectSubmit}},[_c('a-form-item',{attrs:{"label":"Selectionnez l'agent"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'collecteur',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Collecteur est vide!',
                      } ],
                  } ]),expression:"[\n                  'collecteur',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Collecteur est vide!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selectionnez le collecteur"}},_vm._l((_vm.collecteurs_not_affect),function(collecteur,index){return _c('a-select-option',{key:index,attrs:{"value":collecteur.id}},[_vm._v(" "+_vm._s(collecteur.nom)+" "+_vm._s(collecteur.prenom)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code_secret',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Code secret incorrect!',
                      } ],
                  } ]),expression:"[\n                  'code_secret',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Code secret incorrect!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"type":"number","placeholder":"Code secret"}})],1),_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Affecter ")])],1)],1),[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Affecter un agent superviseur")])],_c('a-form',{staticClass:"affect-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.formSuperviseurAffect,"hideRequiredMark":true},on:{"submit":_vm.agentSuperviseurSubmit}},[_c('a-form-item',{attrs:{"label":"Selectionnez l'agent"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'superviseur',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Superviseur est vide!',
                      } ],
                  } ]),expression:"[\n                  'superviseur',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Superviseur est vide!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selectionnez le superviseur"}},_vm._l((_vm.superviseurs),function(collecteur,index){return _c('a-select-option',{key:index,attrs:{"value":collecteur.id}},[_vm._v(" "+_vm._s(collecteur.nom)+" "+_vm._s(collecteur.prenom)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code_secret',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Code secret incorrect!',
                      } ],
                  } ]),expression:"[\n                  'code_secret',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Code secret incorrect!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"type":"number","placeholder":"Code secret"}})],1),_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Affecter ")])],1)],1),[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Affecter un agent livreur")])],_c('a-form',{staticClass:"affect-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.formLivreurAffect,"hideRequiredMark":true},on:{"submit":_vm.agentLivreurSubmit}},[_c('a-form-item',{attrs:{"label":"Selectionnez l'agent"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'livreur',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Livreur est vide!',
                      } ],
                  } ]),expression:"[\n                  'livreur',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Livreur est vide!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selectionnez le livreur"}},_vm._l((_vm.livreurs),function(collecteur,index){return _c('a-select-option',{key:index,attrs:{"value":collecteur.id}},[_vm._v(" "+_vm._s(collecteur.nom)+" "+_vm._s(collecteur.prenom)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code_secret',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Code secret incorrect!',
                      } ],
                  } ]),expression:"[\n                  'code_secret',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Code secret incorrect!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"type":"number","placeholder":"Code secret"}})],1),_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Affecter ")])],1)],1),[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Affecter un caissier")])],_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.formCaissier,"hideRequiredMark":true},on:{"submit":_vm.agentCaissierSubmit}},[_c('a-form-item',{attrs:{"label":"Selectionnez le caissier"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'caissier',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Caissier est vide!',
                      } ],
                  } ]),expression:"[\n                  'caissier',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Caissier est vide!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selectionnez le caissier"}},_vm._l((_vm.caissiers),function(collecteur,index){return _c('a-select-option',{key:index,attrs:{"value":collecteur.id}},[_vm._v(" "+_vm._s(collecteur.nom)+" "+_vm._s(collecteur.prenom)+" - (+228) "+_vm._s(collecteur.numero)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code_secret',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Code secret incorrect!',
                      } ],
                  } ]),expression:"[\n                  'code_secret',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Code secret incorrect!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"type":"text","placeholder":"Code secret"}})],1),_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Affecter ")])],1)],1)],2)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Liste des caissiers affectés")])]},proxy:true}])},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data_c},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('router-link',{staticClass:"mx-2",attrs:{"to":{
                  name: 'Caissier_detail',
                  params: { id: record.key },
                }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }